<template lang="pug">
BasePane
  template(#title)
    sidebar-title(:selectedRowColumns="selectedRowColumns")
  BackgroundAndBorder(typeOfComponent="column" shadow :deviceSelector="false")
    template(#color)
      OmColorInput(
        :layout="columnColorInputLayout"
        :size="columnColorInputSize"
        :label="$t('column')"
        typeOfComponent="column"
        dsAllowed
      )
      OmColorInput(:label="$t('block')" layout="col" typeOfComponent="row" dsAllowed)
      OmColorInput(
        :label="$t('page')"
        property="selectedPage.$device.background.color"
        typeOfComponent="global"
        layout="col"
        dsAllowed
      )
  hr.mt-1.mb-4
  Accordion(fix)
    template(#title) {{ $t('sizeAndSpacing') }}
    template(#device)
      DeviceSelector(:hasViewText="false")
    Margin
    transition(name="fade")
      RangeInput(
        v-if="mobilePreview"
        :label="$t('minHeight')"
        v-model.number="minHeight"
        :min="0"
        :max="2000"
        :step="1"
        :placeholder="mobileMinHeightPlaceholder"
        :editMobile="false"
      )
  hr.mt-4.mb-3
  template(#advancedContent)
    Padding.mb-2
    Dropdown#align-vertical(
      v-if="!hasWheel"
      layout="col"
      :label="$t('verticalAlignment')"
      v-model="alignmentValue"
      :items="alignmentOptions"
      editMobile
    )
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import Padding from '@/components/Editor/Controls/InputGroup/Padding.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import BackgroundAndBorder from '@/components/Editor/Blocks/BackgroundAndBorder.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import columnMixin from '@/editor/mixins/column';
  import itemMixin from '@/editor/mixins/item';
  import BasePane from './BasePane.vue';
  import DeviceSelector from '../../components/DeviceSelector.vue';

  export default {
    components: {
      BasePane,
      Accordion,
      Margin,
      Padding,
      Hide,
      BackgroundAndBorder,
      Dropdown,
      RangeInput,
      DeviceSelector,
    },
    mixins: [columnMixin, itemMixin],

    data: () => ({
      canEditMobile: true,
      selectedColIndex: 0,
      selectedRowColumns: [],
    }),

    computed: {
      ...mapGetters(['isNano', 'isFullscreen', 'isTeaserPage']),
      ...mapState(['selectedColumn', 'selectedRow', 'mobilePreview', 'hasWheel']),
      isHeightResizerEnabled() {
        return this.mobilePreview;
      },
      spacingProps() {
        return {
          showPadding: true,
          typeOfComponent: 'col',
        };
      },
      hasImageSet() {
        return !!this.getValueOf('$device.background.imageUrl');
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
      alignmentOptions() {
        const options = this.isNano
          ? [
              { key: this.$t('left'), value: 'left' },
              { key: this.$t('center'), value: 'center' },
              { key: this.$t('right'), value: 'right' },
              { key: this.$t('Space between'), value: 'space-between' },
            ]
          : [
              { text: this.$t('top'), value: 'flex-start' },
              { text: this.$t('center'), value: 'center' },
              { text: this.$t('bottom'), value: 'flex-end' },
            ];

        if (this.isFullscreen && this.mobilePreview && !this.isTeaserPage) {
          options.unshift({ text: this.$t('Space evenly'), value: 'normal' });
        }

        if (this.mobilePreview) {
          options.unshift({ text: this.$t('default'), value: null });
        }

        return options;
      },
      alignmentSettingPath() {
        if (this.isNano) return 'desktop.contentAligment';

        if (this.isFullscreen && this.mobilePreview && !this.isTeaserPage) {
          return 'mobile.alignContent';
        }

        return '$device.verticalAlign';
      },
      alignmentValue: {
        get() {
          return this.getValueOf(this.alignmentSettingPath);
        },
        set(value) {
          this.setValueOf(this.alignmentSettingPath, value);
        },
      },
      columnBackgroundType() {
        return this.getValueOf('$device.background.type');
      },
      columnColorInputSize() {
        return this.columnBackgroundType === 'image' ? 'lg' : 'sm';
      },
      columnColorInputLayout() {
        return this.columnBackgroundType === 'image' ? 'row' : 'col';
      },
      minHeight: {
        get() {
          return this.getValueOf('mobile.minHeight');
        },
        set(value) {
          this.setValueOf('mobile.minHeight', value);
        },
      },
      mobileMinHeightPlaceholder() {
        if (this.mobilePreview && !this.getValueOf('mobile.minHeight')) {
          return `${this.getValueOf('desktop.minHeight', 0)}`;
        }
        return undefined;
      },
    },

    created() {
      if (window.om.template) {
        this.selectedRowColumns = window.om.template.elements.filter(
          (e) => e.type === 'OmCol' && e.rowId === this.selectedColumn.rowId,
        );
        this.selectedColIndex = this.selectedRowColumns.findIndex(
          (c) => c.uid === this.selectedColumn.uid,
        );
      }
    },
  };
</script>
